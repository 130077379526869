import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <Seo
        title="Business Leadership - Jeff Bartholomew - Websuasion Podcast"
        ogDescription="Jeff Bartholomew consulting practice has guided more than 1,000 clients through strategy, sales, leadership, branding, and communication."
        image="/images/podcast/season-one/jeff-bartholomew.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h1>Business Leadership and Valuation with Jeff Bartholomew</h1>
						<h3>Everest Business Coaching</h3>
						<p>Jeff has more than 30 years of experience working for Fortune 500 company Steelcase. He worked as a Director of Coaching and  Development, conducting more than 400 individual one-one-one coaching sessions. In his own consulting practice he's guided more than 1,000 clients through strategy, sales, leadership, branding, and communication.</p>
						<p>Jeff has serves on the board of J. Whitney Bunting School of Business at Georgia College and as Chair of the Marketing Advisory Board.</p>
		        <p>
		          <QuickLinkButton
		            content={"Everest Business Coaching"}
		            url={"https://everestbusinesscoaching.com"}
		            icon={"linkify"}
		          />
		        </p>
		        <p>
		          <QuickLinkButton
		            content={"Jeff on LinkedIn"}
		            url={"https://www.linkedin.com/in/jeffbartholomew1"}
		            icon={"linkify"}
		          />
		        </p>
						<p>In this second episode of Websuasion Conversation, we discuss taking risks, self-doubt, teaching, making connections, communication, accountability, and exit strategies.</p>
					</div>
        }
        textRight={
          <div className="centered">
            <img src="/images/podcast/season-one/jeff-bartholomew.jpg" className="rounded-square" alt="Business Leadership and Valuation with Jeff Bartholomew" width="300" />
					</div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        text={
	          <div>
							<h2>Listen Now</h2>
						  <AudioPlayer
						    src="https://websuasion-conversation.s3.amazonaws.com/WebsuasionConversationEpisode002-JeffBartholomew.mp3"
						  />
							<h3>Subscribe to the podcast</h3>
							<p>
								<a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479" target="_blank" rel="noreferrer">
									<img src="/images/podcast/apple-podcasts-150x150.png" className="icon" alt="Websuasion Conversasion on Apple Podcasts" width="50" />
								</a>
								<a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG" target="_blank" rel="noreferrer">
									<img src="/images/podcast/spotify-150x150.png" className="icon" alt="Websuasion Conversasion on Spotify" width="50" />
								</a>
							</p>
							<p>
								Podcast RSS feed:<br /><br />

								<span className="red">https://websuasion.com/podcast.xml</span>
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Introduction</h2>
							<p>
								Jeff Bartholomew tells us about his 30-year global sales background at Steelcase, product launching with Williams Sonoma, and his decision to start business coaching.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/XMWjZSIw5XE'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Taking Risks in Business</h2>
							<p>
								Jeff discusses the tendency for new business owners to be tepid in their choices.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/drWTHTUUAhY'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Self Doubt in Business</h2>
							<p>
								Jeff talks about overcoming self-doubt in your business and how "we paint our own picture".
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/Au1d78Rsk5c'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>J. Whitney Bunting School</h2>
							<p>
								Jeff talks about his involvement with teaching and mentoring at the J. Whitney Bunting School of Business at Georgia College.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/nH86DtkEPTE'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Experience and Connections</h2>
							<p>
								Jeff tells us how his experience and connections allow him to make critical connections for his clients. 
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/s3KaSdWkcVI'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Teaching Makes You Better</h2>
							<p>
								Jeff discusses how teaching gives him a greater level of understanding of selling methodology.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/TIi8qdgccB8'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Eat That Frog</h2>
							<p>
								Jeff tells us about the “Eat That Frog” business concept based on a Mark Twain quote.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/KEOg_5tNEmA'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Ambivert Communication</h2>
							<p>
								In episode two of the Websuasion Conversation podcast, Jeff Bartholomew talks about how he teaches business owners to become ambiverts for better communication.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/bDlviiJqimk'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Accountability</h2>
							<p>
								Jeff shares how he holds his clients accountable for their progress using KPI (Key Performance Indicators) and KRI (Key Result Indicators). 
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/knV5m5ZDpL0'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Business Valuation</h2>
							<p>
								Jeff talks about having an exit strategy and how he improves valuation when selling a business. 
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/OJ2QSgDkyss'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Entrepreneurial Growth Program</h2>
							<p>
								Jeff explains how his eight-week Entrepreneurial Growth program guides business owners though 3-year strategic planning, sales, marketing, branding, and operations.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/TE1H6ItJwP8'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h3>Your Prospect-Building Efforts</h3>
							<p>
								This week's homework is for you to decide on the social media platforms your target audience uses. Ask yourself, are you publishing frequent content to those platforms? When you do, are you providing guidance and encouraging interaction. If not, make a list of marketing assets you can create that will entice prospects to connect with you.
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/podcast/season-one/content.jpg" className="rounded-image" alt="Content Marketing" width="620" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
